<template>
  <div>
    <button class="btn btn-outline-blue mb-3" @click="addAddress">
      <icon type="plus" /> Add Address
    </button>
    <alert class="my-4" v-if="!user" />
    <alert
      :hideLoader="true"
      class="my-4"
      v-if="user && user.addresses.length == 0"
    >
      No Addresses</alert
    >
    <div
      class="card border-0 mobile-tablet-no-card table-container"
      v-if="user && user.addresses.length > 0"
    >
      <GoogleMap
        ref="gmap"
        v-if="primaryAddress"
        :key="primaryAddress.id"
        :markers="[primaryAddress]"
      ></GoogleMap>
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Address</th>
            <th>Unit / Suite</th>
            <th>City</th>
            <th>Province</th>
            <th>Country</th>
            <th>Postal Code</th>
            <th class="text-center">Billing</th>
            <th class="text-center">Residence</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="address in user.addresses" :key="address.id">
            <td>
              <b class="mobile-tablet-only mr-2">Address:</b
              >{{ address.house }} {{ address.street }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Unit / Suite:</b>
              {{ address.unit || "N/A" }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">City:</b>{{ address.city }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Province:</b
              >{{ address.state }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Country:</b
              >{{ address.country }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Postal Code:</b
              >{{ address.zip }}
            </td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Billing:</b>
              <span v-if="address.billing" class="text-success h3">
                <icon type="check" />
              </span>
              <span v-else class="text-danger h3">
                <icon type="times" />
              </span>
            </td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Residence:</b>
              <span v-if="address.shipping" class="text-success h3">
                <icon type="check" />
              </span>
              <span v-else class="text-danger h3">
                <icon type="times" />
              </span>
            </td>
            <td class="text-center td-actions">
              <button
                class="btn btn-danger btn-sm"
                @click="deleteAddress(address)"
                :disabled="address.isDeleting"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <icon type="trash" v-if="!address.isDeleting" />
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="address.isDeleting"
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GoogleMap from "@/components/GoogleMap.vue";
import MarkerModal from "../../../admin/locations/PatientMarkerModal";
import Vue from "vue";

export default {
  name: "AdminAddress",
  computed: {
    ...mapState({
      user: (state) => state.Profile.user,
    }),
    primaryAddress: function () {
      if (this.user.addresses.length > 0) {
        let primary = false;
        this.user.addresses.filter((a) => {
          if (!primary) {
            if (a.shipping) {
              primary = a;
            }
          }
        });
        if (!primary && this.user.addresses[0]) {
          primary = this.user.addresses[0];
        } else {
          return false;
        }
        const marker = {
          lat: parseFloat(primary.lat),
          lng: parseFloat(primary.lng),
          data: {
            address: {
              street: primary.street,
              city: primary.city,
              state: primary.state,
              country: primary.country,
            },
          },
        };
        marker.content = this.renderMarkerModal(marker);
        return marker;
      }
      return false;
    },
    src: function () {
      return this.$route.query.src;
    },
  },
  methods: {
    addAddress: function () {
      this.$router.push({
        name: "admin.profile.address.create",
        query: { src: "addresses" },
      });
    },
    deleteAddress: function (a) {
      let vm = this;
      vm.$swal({
        title: "Are you sure",
        text: "Are you sure you want to delete this address?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          a.isDeleting = true;
          vm.$store.dispatch("Profile/removeAddress", {
            user: vm.user.id,
            id: a.id,
          });
        } else {
          a.isDeleting = false;
        }
      });
    },
    renderMarkerModal(marker) {
      var ComponentClass = Vue.extend(MarkerModal);
      var instance = new ComponentClass({
        propsData: {
          data: marker.data,
        },
      });
      instance.$mount();
      return instance.$el;
    },
  },
  components: {
    GoogleMap,
  },
};
</script>
